<template>
  <ul class="nav nav-tabs nav-fill">
    <li class="nav-item" v-for="tab in tabs" :key="tab.id">
      <router-link class="btn nav-link" active-class="active" :to="{name: tab.id}">{{ tab.text }}</router-link>
    </li>
  </ul>
  <div class="container">
    <div class="row shadow-sm bg-light py-2">
      <div class="col-sm-12">
        <RouterView/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    tabs() {
      const tabs = [
        {id: 'edit.auction.data', text: 'المزاد'},
        {id: 'auctions.status', text: 'الحالة'},
        {id: 'auctions.images', text: 'الصور'},
        {id: 'auctions.notes', text: 'الملاحظات'},
      ]

      if (this.$store.getters.superAdmin) {
        tabs.push({id: 'auctions.bids', text: 'المزاودات'})
        tabs.push({id: 'auctions.log', text: 'سجل المزاد'})
      }

      return tabs
    },
  }
}
</script>
<style scoped>
.nav-link {
  width: 100%;
  border-radius: 0;
  background-color: #f06b78;
  color: white;
}

.active {
  background-color: white;
  color: #f06b78;
}
</style>
